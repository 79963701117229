import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import VueRouter from 'vue-router';
// import router from './router'
// import store from './store'

import { store } from './store/store';
import { routes } from './router/routes';

import NProgress from 'nprogress';
import VueProgress from 'vue-progress';
Vue.use(VueProgress);

import firebase from 'firebase/compat/app';
// import firebaseui from 'firebaseui';

import 'firebase/compat/auth'; // for authentication
import 'firebase/compat/storage'; // for storage
//import 'firebase/compat/database';    // for realtime database
import 'firebase/compat/firestore'; // for cloud firestore
//import 'firebase/compat/messaging';   // for cloud messaging
import 'firebase/compat/functions'; // for cloud functions

import './assets/css/style.scss';

Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

import LazyLoadDirective from './directives/LazyLoadDirective';
Vue.directive('lazyload', LazyLoadDirective);

import BackToTop from 'vue-backtotop';
Vue.use(BackToTop);

import browserDetect from 'vue-browser-detect-plugin';
Vue.use(browserDetect);

import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import Datetime from 'vue-datetime';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';
Vue.use(Datetime);

import {
    faCoffee,
    faUserAlt,
    faShip,
    faExclamationTriangle,
    faUser,
    faUsers,
    faFish,
    faFire,
    faHome,
    faBriefcase,
    faUserGraduate,
    faCloud,
    faLifeRing,
    faCheckCircle,
    faMoneyBillAlt,
    faThumbsUp,
    faLink,
    faInfo,
    faInfoCircle,
    faQuestionCircle,
    faCog,
    faList,
    faTimes,
    faCheck,
    faEnvelope,
    faPhone,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faLock,
    faShoppingCart,
    faWrench,
    faStopwatch,
    faOilCan,
    faStar,
    faVolumeUp,
    faPrint,
    faRegistered,
    faFileSignature,
    faListUl,
    faClipboardList,
    faAnchor,
    faPlay,
    faPen,
    faSearch,
    faCamera,
    faCalendarCheck,
    faTrashAlt,
    faSyncAlt,
    faGasPump,
    faCircle,
    faMinusCircle,
    faQuoteRight,
    faComment,
    faEye,
    faEyeSlash,
    faGlobe,
    faComments,
    faCheckSquare,
    faSquare,
    faMinus,
    faPlus,
    faLaptop,
    faHandshake,
    faMobile,
    faMobileAlt,
    faAddressBook,
    faMinusSquare
} from '@fortawesome/free-solid-svg-icons';

// import InstantSearch from 'vue-instantsearch';

// Vue.use(InstantSearch);

import {
    // faLinkedin,
    // faTwitter,
    faFacebookF,
    faFacebookSquare,
    faLinkedin
    //faInstagram,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faCoffee,
    faUserAlt,
    faShip,
    faUsers,
    faUser,
    faFish,
    faExclamationTriangle,
    faFire,
    faHome,
    faBriefcase,
    faUserGraduate,
    faCloud,
    faLifeRing,
    faCheckCircle,
    faMoneyBillAlt,
    faThumbsUp,
    faLink,
    faInfoCircle,
    faInfo,
    faQuestionCircle,
    faCog,
    faList,
    faTimes,
    faCheck,
    faEnvelope,
    faPhone,
    faChevronUp,
    faChevronDown,
    faLock,
    faFacebookF,
    faShoppingCart,
    faWrench,
    faStopwatch,
    faOilCan,
    faStar,
    faChevronLeft,
    faChevronRight,
    faVolumeUp,
    faPrint,
    faFileSignature,
    faListUl,
    faClipboardList,
    faAnchor,
    faPlay,
    faPen,
    faRegistered,
    faSearch,
    faCamera,
    faTrashAlt,
    faCalendarCheck,
    faSyncAlt,
    faGasPump,
    faLinkedin,
    faFacebookSquare,
    faCircle,
    faMinusCircle,
    faQuoteRight,
    faComment,
    faEye,
    faEyeSlash,
    faGlobe,
    faComments,
    faCheckSquare,
    faSquare,
    faMinus,
    faPlus,
    faLaptop,
    faHandshake,
    faMobile,
    faMobileAlt,
    faAddressBook,
    faMinusSquare
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

import VueCountryCode from 'vue-country-code';
Vue.use(VueCountryCode);

Vue.use(VueRouter);
const router = new VueRouter({
    routes: routes,
    mode: 'history'
});

import vueSmoothScroll from 'vue2-smooth-scroll';
Vue.use(vueSmoothScroll);

// Firebase config - this is provided when you create your app
// Swap out these settings for your project settings
// const config = {
//   apiKey: "AIzaSyDCfACjsWTekUlyDrisWs4cktqsD4FS3Dw",
//   authDomain: "dev-offshoresms.firebaseapp.com",
//   //authDomain: "offshoresms.com.au",
//   databaseURL: "https://dev-offshoresms.firebaseio.com",
//   projectId: "dev-offshoresms",
//   storageBucket: "dev-offshoresms.appspot.com",
//   messagingSenderId: "15976963230"
// };
const config = {
    apiKey: 'AIzaSyAX5rQ__fBkV2Rd-FvtWB7JPnL7-lMvv4s',
    authDomain: 'app-offshoresms.firebaseapp.com',
    projectId: 'app-offshoresms',
    storageBucket: 'app-offshoresms.appspot.com',
    messagingSenderId: '395258391843',
    appId: '1:395258391843:web:d621d56d614be9f9c8b096',
    measurementId: 'G-30MW3C1M6M'
};

// Initialize Firebase
firebase.initializeApp(config);

export const db = firebase.firestore();
// date issue fix according to firebase
export const settings = {
    //timestampsInSnapshots: true, //deprecated
    // persistence: false,
};
db.settings(settings);

// Check before each page load whether the page requires authentication/
// if it does check whether the user is signed into the web app or
// redirect to the sign-in page to enable them to sign-in

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        // NProgress.start()
    }
    next();
});

router.beforeEach((to, from, next) => {
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth && !currentUser) {
        next('/sign-in');
    } else if (requiresAuth && currentUser) {
        window.scrollTo(0, 0);
        next();
    } else {
        window.scrollTo(0, 0);
        next();
    }
});

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

// Wrap the vue instance in a Firebase onAuthStateChanged method
// This stops the execution of the navigation guard 'beforeEach'
// method until the Firebase initialization ends
firebase.auth().onAuthStateChanged(function(user) {
    new Vue({
        el: '#app',
        store: store,
        router: router,
        render: h => h(App)
    });
});
